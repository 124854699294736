import React, { Component } from 'react';
import Head from 'next/head';
import DepvLayout from '@studionand/depi/src/components/DepvLayout';
import WelcomeSection from '@studionand/depi/src/components/depv/sections/Welcome';
import GallerySection from '@studionand/depi/src/components/depv/sections/Gallery';
import AboutSection from '@studionand/depi/src/components/depv/sections/About';
import MarketDataSection from '@studionand/depi/src/components/depv/sections/MarketData';
import CardsSection from '@studionand/depi/src/components/depv/sections/Cards';
import NewsSection from '@studionand/depi/src/components/depv/sections/News';
import DepiSection from '@studionand/depi/src/components/depv/sections/Depi';
import useStores from '@studionand/depi/src/utils/useStores';

const DepvIndexPage = () => {
  const { dataStore } = useStores();
  const linkContext = process.env.NODE_ENV === 'production' ? '/' : '/depv/';

  return (
    <DepvLayout>
      <Head>
        <title>DEPV - Startseite</title>
      </Head>
      <WelcomeSection />
      <GallerySection />
      <AboutSection />
      <MarketDataSection />
      <NewsSection />
      <CardsSection
        data={dataStore.pages.latestDepvEvents}
        label="Termine"
        href={`${linkContext}termine`}
      />
      <CardsSection
        data={dataStore.pages.latestDepvPublications}
        label="Publikationen"
        href={`${linkContext}mediathek`}
      />
      <DepiSection />
    </DepvLayout>
  );
};

DepvIndexPage.getInitialProps = async ({ dataStore }) => {
  // await dataStore.pages.loadNews();
  // load page for about section
  await dataStore.pages.loadPage('093e3e56-1529-41f6-a115-231570e683db');
  // load page for marketdata section
  // The client decided not to show the pricechart anymore
  // therefore we don't need to load this page anymore 
  // await dataStore.pages.loadPage('30b557ad-9a08-42d3-9937-a89fe942569f');
    await dataStore.pages.loadMarketData();
    await dataStore.pages.loadNews();
    await dataStore.pages.loadEvents();

  return {};
};

export default DepvIndexPage;
